import { APIFilter } from '@/utils/api'
import { TABLA } from '@/utils/consts'

export default {
  async selectPuestoServicio (Vue, idpuestoServicio) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idpuesto_servicio', idpuestoServicio)
    const resp = await Vue.$api.call(
      'puestoServicio.select', { filter: apiFilter }
    )
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectDetails(Vue, idpuestoServicio, esTecnico, esComercial, esVigilante) {
    let apiCalls = []
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idpuesto_servicio', idpuestoServicio)
      .addGT('estado', 0)
    apiCalls.push({
      name: 'selectpuestoServicioServicioCount',
      method: 'puestoServicioServicio.select',
      params: { filter: apiFilter, wrapper: 'count' }
    })
    apiCalls.push(Vue.$online.fichero.ApiCallSelectCount(idpuestoServicio, TABLA.puesto_servicio.idtabla, esTecnico, esComercial, esVigilante))
    return await Vue.$api.batchCall(apiCalls)
  },
}
